import "./shared/styles/index.css";

import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import store from "./shared/store";
import App from "./App";
import Theme from "./shared/styles/Theme";
import { QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { queryClient } from "./shared/lib/reactQuery";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { OneSignalProvider } from "./shared/lib/push";

// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

ReactDOM.render(
  <Provider store={store}>
    <Theme>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <OneSignalProvider>
              <App />
            </OneSignalProvider>
          </LocalizationProvider>
        </SnackbarProvider>
        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
    </Theme>
  </Provider>,
  document.getElementById("root")
);
