import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { getClient } from "../../../../../../shared/config/urlConfig";
import _cookies from "../../../../../../shared/utils/_cookies";
import Loading from "../../../../../components/Loading";
import { getProfessorQuestions } from "../../../../../../shared/store/survey";
import { bindActionCreators } from "redux";
const ButtonStyled = styled(LoadingButton)(() => ({
  color: "white",
}));

const domain = getClient();
const baseUrlInf = "https://cadernovirtual.inf.br";

function QuestionList({
  status,
  professor,
  getProfessorQuestions,
  professorQuestions,
  currentEvaluation,

  value,
  onChange,
  inEditing,
  ...props
}) {
  const [loading, setLoading] = useState({});
  useEffect(() => {
    console.log({ currentEvaluation });
  }, [currentEvaluation]);
  const {
    token,
    user: { username },
  } = _cookies.getItem("auth");
  useEffect(() => {
    const getQuestions = async () => {
      const type = currentEvaluation?.type === "CAP" ? "CAP" : "ECAP";
      const surveyid = currentEvaluation?.surveyId;
      getProfessorQuestions(token, professor.ID, type, surveyid);
    };
    getQuestions();
  }, [token, username]);

  const handleSelectQuestion = (question, index) => {
    setLoading({ [index]: true });
    axios
      .get(
        `https://cadernovirtual.inf.br/survey-service2/surveyQuestions?surveyQuestionId=${question.questionId}`,
        {
          headers: { "Access-Control-Allow-Origin": "*", domain },
        }
      )
      .then(({ data: { object } }) => {
        setLoading(false);
        onChange({
          target: {
            value: { ...object[0], inEditing: true, isRegisteredQuetion: true },
          },
        });
      });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Enunciado</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {professorQuestions?.map((question, index) => (
              <TableRow
                key={question.questionId}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{question.questionId}</TableCell>
                <TableCell
                  dangerouslySetInnerHTML={{
                    __html: question.questionDescription,
                  }}
                ></TableCell>
                <TableCell>{question.type}</TableCell>
                <TableCell>
                  <ButtonStyled
                    variant="contained"
                    loading={loading[index]}
                    onClick={() => handleSelectQuestion(question, index)}
                  >
                    Selecionar
                  </ButtonStyled>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {status === "loading" && (
        <Box position="relative" height="5rem">
          <Loading />
        </Box>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  status: state.survey.status.PROFESSOR_QUESTIONS_LIST,
  professor: state.auth.user.profile.isProfessor,
  currentEvaluation: state.evaluation.currentEvaluation,
  professorQuestions: state.survey.professorQuestions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getProfessorQuestions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuestionList);
