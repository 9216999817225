import { Notifications, NotificationsOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { Button, IconButton, styled } from "@mui/material";
import { createNotification } from "../../../shared/store/notifications";
import OneSignal from "react-onesignal";
import {
  useOneSignalOptIn,
  useOneSignalOptOut,
} from "../../../shared/lib/push";
import { useTenantParameters } from "../api/getTenantParameters";

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "white",
  "& > .MuiIcon-root": { height: "36px", width: "36px" },
  height: "36px",
  width: "36px",
}));

export default function TogglePushNotifications() {
  const OneSignalStore = useSelector((state) => state?.OneSignal);
  const OneSignalOptIn = useOneSignalOptIn();
  const OneSignalOptOut = useOneSignalOptOut();
  const tenantParams = useTenantParameters();

  OneSignal.Notifications.addEventListener("change", function (event) {
    console.log("r22", OneSignal);
  });

  const handleLog = () => {
    console.log("log", OneSignal.User.PushSubscription.optedIn, OneSignal);
  };
  const handleClickOptIn = () => {
    handleLog();
    if (OneSignal.Notifications.permission) {
      OneSignal.Slidedown.promptPush();
      OneSignalOptIn.mutate();
    } else {
      OneSignal.Notifications.requestPermission();
    }
  };
  const handleClickOptOut = () => {
    handleLog();
    OneSignalOptOut.mutate();
  };
  console.log({
    OneSignalAppId: tenantParams.data?.Onesignal_AppID,
    optedIn: OneSignalStore.optedIn,
    OneSignalIsSupported: OneSignal.Notifications.isPushSupported(),
  });
  if (
    OneSignalStore.optedIn === undefined ||
    !tenantParams.data?.Onesignal_AppID
  ) {
    return <></>;
  } else if (!OneSignal.Notifications.isPushSupported()) {
    return (
      <StyledIconButton disabled color="error">
        <NotificationsOff />
      </StyledIconButton>
    );
  } else {
    return (
      <>
        {!OneSignalStore.optedIn && (
          <StyledIconButton onClick={handleClickOptIn}>
            <Notifications />
          </StyledIconButton>
        )}
      </>
    );
  }
}
